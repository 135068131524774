// PrivacyPolicy.js

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  
  margin: 0 auto;
  padding: 50px;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(169, 179, 193);
  background-color: #101522;
`;

const Title = styled.h1`
  color: #fff;
  font-family: cursive;
  margin-bottom: 15px;
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-family: cursive;
  margin-top: 20px;
  margin-bottom: 15px;

`;
const SectionTitle2 = styled.h3`
  color: #fff;
  font-family: cursive;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 15px;

`;
const SectionTitle3 = styled.li`
  color: #fff;
  font-family: cursive;
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 15px;

`;

const Paragraph = styled.p`
  line-height: 1.6;
  margin-bottom: 15px;
  margin-bottom: 0px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: underline;
`;

const PrivacyPolicy = () => {
    return (
        <Container>
            <Title>Mount Software Service - Privacy Policy</Title>
            

            <SectionTitle>1. Information We Collect</SectionTitle>
            <Paragraph>
                We may collect and process the following information for profiling.
            </Paragraph>
            <SectionTitle2>User Account Information:</SectionTitle2>
            <SectionTitle3>Usernames</SectionTitle3>
            <SectionTitle3>Email addresses</SectionTitle3>
            <SectionTitle3>Passwords (usually encrypted)</SectionTitle3>
            <SectionTitle3>Profile information</SectionTitle3>

            <SectionTitle2>Usage Data:</SectionTitle2>
            <SectionTitle3>Pages visited</SectionTitle3>
            <SectionTitle3>Actions performed on the portal</SectionTitle3>
            <SectionTitle3>Time spent on the portal</SectionTitle3>
            <SectionTitle3>Click-through rates</SectionTitle3>

            <SectionTitle2>Device Information:</SectionTitle2>
            <SectionTitle3>Device type</SectionTitle3>
            <SectionTitle3>Operating system</SectionTitle3>
            <SectionTitle3>Browser type and version</SectionTitle3>

            <SectionTitle2>Location Data:</SectionTitle2>
            <SectionTitle3>IP addresses</SectionTitle3>
            <SectionTitle3>Geographical location (if permitted by the user)</SectionTitle3>

            <SectionTitle2>Preferences:</SectionTitle2>
            <SectionTitle3>Customized settings chosen by the user</SectionTitle3>
            <SectionTitle3>Language preferences</SectionTitle3>

            <SectionTitle2>Transaction Information:</SectionTitle2>
            <SectionTitle3>If the portal involves transactions, it may collect payment information, purchase history, and related details.</SectionTitle3>
            
            <SectionTitle2>Communication Data:</SectionTitle2>
            <SectionTitle3>Messages sent or received within the portal</SectionTitle3>
            <SectionTitle3>Chat logs</SectionTitle3>
            <SectionTitle3>Support requests</SectionTitle3>
            
            <SectionTitle2>Surveys and Feedback:</SectionTitle2>
            <SectionTitle3>Information provided through surveys or feedback forms</SectionTitle3>
            
            <SectionTitle2>Cookies and Tracking Technologies:</SectionTitle2>
            <SectionTitle3>Cookies for authentication and tracking user behavior</SectionTitle3>
            <SectionTitle3>Other tracking technologies for analytics purposes</SectionTitle3>
            
            <SectionTitle2>Third-Party Integrations:</SectionTitle2>
            <SectionTitle3>Information from third-party services integrated into the portal</SectionTitle3>
                     


            <SectionTitle>2. How We Use Your Information</SectionTitle>
            <Paragraph>
                We use the information we collect for data analysis and better app performance.<br />
                Here are common ways in which we might use the information it collects:
            </Paragraph>

            <SectionTitle2>User Authentication:</SectionTitle2>
            <SectionTitle3>Utilizes login credentials (username, password) to authenticate and authorize users.</SectionTitle3>

            <SectionTitle2>Personalization:</SectionTitle2>
            <SectionTitle3>Customizes the user experience based on preferences, settings, and historical usage patterns.</SectionTitle3>

            <SectionTitle2>Service Provision:</SectionTitle2>
            <SectionTitle3>Provides the core services or functionalities offered by the portal, such as access to resources, tools, or information.</SectionTitle3>

            <SectionTitle2>Communication:</SectionTitle2>
            <SectionTitle3>Facilitates communication between users within the portal (e.g., messaging, chat features).</SectionTitle3>

            <SectionTitle2>Analytics and Improvement:</SectionTitle2>
            <SectionTitle3>Gathers data on user behavior, page views, and interactions to analyze and improve the portal's performance, features, and content.</SectionTitle3>

            <SectionTitle2>Transactional Processing:</SectionTitle2>
            <SectionTitle3>Manages and processes transactions if the portal involves financial transactions, purchases, or subscriptions.</SectionTitle3>

            <SectionTitle2>Customer Support:</SectionTitle2>
            <SectionTitle3>Uses information to respond to user inquiries, provide support, and address issues.</SectionTitle3>

            <SectionTitle2>Security:</SectionTitle2>
            <SectionTitle3>Monitors user activities to ensure the security of the portal and its users.</SectionTitle3>

            <SectionTitle2>Personalized Content and Advertising:</SectionTitle2>
            <SectionTitle3>Displays content and advertisements tailored to the user's preferences and behavior within the portal.</SectionTitle3>

            <SectionTitle2>Surveys and Feedback:</SectionTitle2>
            <SectionTitle3>Utilizes feedback and survey responses to enhance user experience and make improvements.</SectionTitle3>

            <SectionTitle2>Legal Compliance:</SectionTitle2>
            <SectionTitle3>Adheres to legal and regulatory requirements regarding data collection, processing, and storage.</SectionTitle3>



            <SectionTitle>3. Cookies</SectionTitle>
            <Paragraph>
                Our website uses cookies. By using our website and agreeing to this
                policy, you consent to our use of cookies.
            </Paragraph>

            <SectionTitle>4. Data Security</SectionTitle>
            <Paragraph>
                We are committed to ensuring that your information is secure. We have
                implemented suitable physical, electronic, and managerial procedures to
                safeguard and secure the information we collect online.<br />

                Data security is a critical aspect of information management, involving the implementation
                of measures to protect data from unauthorized access, disclosure, alteration, and destruction.
                Here are key components and best practices for ensuring data security:
            </Paragraph>

            <SectionTitle2>Access Controls:</SectionTitle2>
            <SectionTitle3>Implement strict access controls to ensure that only authorized individuals have access to specific data.</SectionTitle3>
            <SectionTitle3>Use role-based access control (RBAC) to assign access permissions based on users' roles and responsibilities.</SectionTitle3>

            <SectionTitle2>Encryption:</SectionTitle2>
            <SectionTitle3>Employ encryption techniques to protect data during transmission (e.g., HTTPS) and while at rest (e.g., encrypting stored data on servers or databases).</SectionTitle3>
            
            <SectionTitle2>Firewalls and Intrusion Detection/Prevention Systems:</SectionTitle2>
            <SectionTitle3>Use firewalls to monitor and control incoming and outgoing network traffic.</SectionTitle3>
            <SectionTitle3>Implement intrusion detection and prevention systems to identify and respond to potential security threats.</SectionTitle3>

            <SectionTitle2>Regular Audits and Monitoring:</SectionTitle2>
            <SectionTitle3>Conduct regular security audits and monitoring to identify and address vulnerabilities or suspicious activities.</SectionTitle3>

            <SectionTitle2>Data Backups:</SectionTitle2>
            <SectionTitle3>Regularly back up critical data to ensure that it can be recovered in the event of data loss, corruption, or a security incident.</SectionTitle3>

            <SectionTitle2>Secure Authentication:</SectionTitle2>
            <SectionTitle3>Enforce strong password policies and consider implementing multi-factor authentication (MFA) for an additional layer of security.</SectionTitle3>

            <SectionTitle2>Security Training and Awareness:</SectionTitle2>
            <SectionTitle3>Provide training to employees on security best practices and raise awareness about potential security threats, such as phishing attacks.</SectionTitle3>

            <SectionTitle2>Incident Response Plan:</SectionTitle2>
            <SectionTitle3>Develop and maintain an incident response plan to efficiently respond to and mitigate the impact of security incidents.</SectionTitle3>

            <SectionTitle2>Secure Development Practices:</SectionTitle2>
            <SectionTitle3>Follow secure coding practices during the development of software applications to minimize vulnerabilities.</SectionTitle3>

            <SectionTitle2>Vendor Security:</SectionTitle2>
            <SectionTitle3>If third-party vendors are involved, ensure they adhere to robust security practices and conduct regular security assessments of their systems.</SectionTitle3>

            <SectionTitle2>Data Classification:</SectionTitle2>
            <SectionTitle3>Classify data based on sensitivity and implement appropriate security measures according to its classification.</SectionTitle3>

            <SectionTitle2>Compliance with Regulations:</SectionTitle2>
            <SectionTitle3>Ensure compliance with relevant data protection and privacy regulations, such as GDPR, HIPAA, or other industry-specific standards.</SectionTitle3>

            <SectionTitle2>Physical Security:</SectionTitle2>
            <SectionTitle3>Secure physical access to servers, data centers, and other infrastructure to prevent unauthorized access or tampering.</SectionTitle3>

            <SectionTitle2>Regular Software Updates and Patching:</SectionTitle2>
            <SectionTitle3>Keep software, operating systems, and applications up to date with the latest security patches to address known vulnerabilities.</SectionTitle3>



            <SectionTitle>5. Changes to This Privacy Policy</SectionTitle>
            <Paragraph>
                We may update our Privacy Policy from time to time. You are advised to
                review this Privacy Policy periodically for any changes.
            </Paragraph>

            <SectionTitle>6. Contact Information</SectionTitle>
            <Paragraph>
                If you have any questions about our Privacy Policy, please contact us at{' '}
                <Link href="mailto:info@mounts.in">
                    info@mounts.in
                </Link>
                .
            </Paragraph>
        </Container>
    );
};

export default PrivacyPolicy;
