import React from 'react';
import {Pricing } from '../../components';

function Products() {
  return (
    <>
    <Pricing />
    
  </>
  );
}

export default Products;
