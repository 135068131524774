import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Footer, Navbar } from './components';
import ScrollToTop from './components/ScrollToTop';
import GlobalStyle from './globalStyles';
import Home from './pages/HomePage/Home';
import Blog from './pages/Blog/Blog';
import Products from './pages/Products/Products';
import Services from './pages/Services/Services';
import ContactUs from './pages/Contact/ContactUs';
import TermsOfService from './pages/Contact/TermsOfService';
import PrivacyPolicy from './pages/Contact/PrivacyPolicy';
import Recentsuccess from './pages/Contact/Recentsuccess';
import Clients from './pages/Client/Client';

function App() {
  return (<Router>
            <GlobalStyle />
            <ScrollToTop  />
            <Navbar />
            <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/Aboutus' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/clients' component={Clients} />
          <Route path='/Blog' component={Blog} />
          <Route path='/Contact' component={ContactUs} />
          <Route path='/Terms' component={TermsOfService} />
          <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
          <Route path='/Recentsuccess' component={Recentsuccess} />
             </Switch>

              < Footer  />
       </Router>
  );
}

export default App;
