// PrivacyPolicy.js

import React from 'react';
import styled from 'styled-components';
import { Container, Button } from '../../globalStyles';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    ImgWrapper,
    Img
} from '../../components/InfoSection/InfoSection.elements';


const Recentsuccess = () => {
    return (<>
        <InfoSec style={{ padding: "16px 0" }}  lightBg={false}>
              <Container style={{ marginTop: "-135" }}>
          <InfoRow imgStart={"start"}>
            <InfoColumn>
              <TextWrapper>
                {/*<TopLine lightTopLine={true}>Story</TopLine>*/}
                <Heading lightText={true}>Recent Success Story</Heading>
                <Subtitle lightTextDesc={true}> The Employment Exchange project is a component of the NCS Portal, integrating 1003 employment
                exchanges nationwide. We successfully implemented it in under 45 days, overcoming challenges that a
                large company struggled with for the past eight years. Our dedication and research in developing the
                unique Cortex Smart Country framework not only brought us joy but also surprised us with the potential
                of Cortex Smart Country.
                </Subtitle>
                             <Subtitle lightTextDesc={true}> The Employment Exchange project plays a vital role in the comprehensive National Career Service
                                Portal [https://www.ncs.gov.in/], inaugurated by the honorable Prime Minister of India, Shri Narendra Modi,
                                in 2014. Seamlessly integrating 1003 employment exchanges nationwide, we accomplished its successful implementation
                                in just 45 days using cortex design, overcoming challenges that had persisted for eight years within a large company.
                                Our unwavering dedication and innovative research in developing the unique Cortex Smart Country framework have not only
                                brought immense satisfaction but have also revealed the substantial potential within the revolutionary Cortex design,
                                contributing to the evolution of an intelligent country ecosystem.<br / >  <br / > 
                </Subtitle>
               
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
                <ImgWrapper >
                    <Img src="/images/Empl.png" alt="Emp" />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
        </>
    );
};

export default Recentsuccess;
