import React from 'react';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour ,
  maindata,
  maindata2,
  maindata3,
  maindata4,
  maindata5,
 } from './Data';
import { InfoSection, Pricing } from '../../components';
import ContactUs from '../Contact/ContactUs';
import Recentsuccess from '../Contact/Recentsuccess';




function Home() {
  return (
      <>
          
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...maindata} />
      <InfoSection {...maindata2} />
      <InfoSection {...maindata3} />
      <InfoSection {...maindata4} />
      <InfoSection {...maindata5} />
          <Recentsuccess />
      <Pricing />
          <InfoSection {...homeObjFour} />
          <ContactUs/>

    </>
  );
}

export default Home;
