import React from 'react';
import ClientPricing from '../../components/ClientPricing/Pricing';

function Clients() {
  return (
      <>
          <ClientPricing />
    
  </>
  );
}

export default Clients;
