
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  
  margin: 0 auto;
  padding: 50px;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(169, 179, 193);
  background-color: #101522;
`;

const Title = styled.h1`
  color: #fff;
  font-family: cursive;
  margin-bottom: 15px;
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-family: cursive;
  margin-top: 20px;
  margin-bottom: 15px;

`;

const SectionTitle2 = styled.h3`
  color: #fff;
  font-family: cursive;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 15px;

`;
const SectionTitle3 = styled.li`
  color: #fff;
  font-family: cursive;
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 15px;

`;

const Paragraph = styled.p`
  line-height: 1.6;
  margin-bottom: 15px;
  margin-bottom: 0px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: underline;
`;

const TermsOfService = () => {
    return (
        <Container>
            <Title>Mount Software Service - Terms of Service</Title>
            

            <SectionTitle>1. Acceptance of Terms</SectionTitle>
            <Paragraph>
                Welcome to Mount Software Service. By using our services, you agree to
                comply with and be bound by these terms of service.<br /><br/>
                By accessing or using our portal, you signify your acceptance of these terms and conditions.
            </Paragraph>

            <SectionTitle3>Acceptance of Terms:</SectionTitle3>
            <Paragraph>By accessing or using Mount Application Services, you agree to comply with
                and be bound by the following terms of service. If you do not agree with any part
                of these terms, please do not use our portal.</Paragraph>

            <SectionTitle3>Changes to Terms:</SectionTitle3>
            <Paragraph>Mount Application Services reserves the right to modify, amend, or update these
                terms at any time. Such changes will be effective immediately upon posting.
                It is your responsibility to review this agreement periodically for any modifications.
                Continued use of the portal after changes are posted constitutes acceptance of those
                changes.</Paragraph>

            <SectionTitle3>User Registration:</SectionTitle3>
            <Paragraph>To access certain features of Mount Application Services, you may be required to
                register for an account. In doing so, you agree to provide accurate, current, and complete
                information during the registration process and to update such information to keep it accurate,
                current, and complete.</Paragraph>

            <SectionTitle3>User Conduct:</SectionTitle3>
            <Paragraph>You agree to use Mount Application Services for lawful purposes and in a way that does not
                infringe on the rights of others, restrict or inhibit anyone else's use, or violate any applicable
                laws.</Paragraph>

            <SectionTitle3>Privacy Policy:</SectionTitle3>
            <Paragraph>Your use of Mount Application Services is also governed by our Privacy Policy, which can be found
                https://www.mounts.in/PrivacyPolicy. By using our portal, you consent to the terms of our Privacy Policy.</Paragraph>

            <SectionTitle3>Termination:</SectionTitle3>
            <Paragraph> Mount Application Services reserves the right, at its sole discretion, to terminate your account or restrict your
                access to the portal at any time, without notice, for any reason.</Paragraph>

            <SectionTitle3>Disclaimer of Warranties:</SectionTitle3>
            <Paragraph> Mount Application Services is provided "as is" and "as available" without any representations or
                warranties, express or implied.</Paragraph>



            <SectionTitle>2. Description of Service</SectionTitle>
            <Paragraph>
                Mount Software Service provides Cloud services. You are
                responsible for obtaining access to the services and that access may
                involve third-party fees (such as Internet service provider or airtime
                charges). <br />
                Click to view our Cloud product and services
                <SectionTitle2><a >https://www.mounts.in/products</a></SectionTitle2>
            </Paragraph>

            <SectionTitle>3. User Responsibilities</SectionTitle>
            <Paragraph>
                You are responsible for maintaining the confidentiality of your account
                and password. You agree to use the services only for lawful purposes
                and in accordance with these terms.
            </Paragraph>

            <SectionTitle>4. Intellectual Property</SectionTitle>
            <Paragraph>
                All content included in our services, including text, graphics, logos,
                and images, is the property of Mount Software Service or its licensors.
            </Paragraph>

            <SectionTitle>5. Limitations of Liability</SectionTitle>
            <Paragraph>
                Mount Software Service shall not be liable for any direct, indirect,
                incidental, special, or consequential damages.
            </Paragraph>

            <SectionTitle>6. Termination</SectionTitle>
            <Paragraph>
                Mount Software Service reserves the right to terminate or suspend your
                account at any time without notice.
            </Paragraph>

            <SectionTitle>7. Governing Law</SectionTitle>
            <Paragraph>
                These terms are governed by and construed in accordance with the laws of
                India.
            </Paragraph>

            <SectionTitle>8. Contact Information</SectionTitle>
            <Paragraph>
                If you have any questions about these terms, please contact us at{' '}
                <Link href="mailto:info@mounts.in">info@mounts.in</Link>.
            </Paragraph>
        </Container>
    );
};

export default TermsOfService;
