export const homeObjBlog = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine2: 'Unveiling the Tapestry of Knowledge',
    topLine: 'Navigating the Frontiers of Human Intellect and Technological Ingenuity',
    descriptions: 'The quality of knowledge intricately intertwined with our understanding of the surrounding environment and the data it encompasses. Our brain possesses a remarkable capacity to conceptualize and envision things that transcend the boundaries of real-world possibilities. Consider gravity, for instance; its complexities eluded comprehension until Sir Isaac Newton enlightened the world about the omnipotent force of universal gravity.',
    description1: `Factual data serves as the primary source for individuals aspiring to enrich their knowledge. The brain's prowess lies not in sheer muscular strength but in its ability to assimilate information. A compelling example of this is the theoretical physicist, Stephen Hawking. Age becomes inconsequential for the brain, remaining impervious to the passage of time. While older individuals may possess a slight advantage in knowledge and experience, the true determinant lies in the quality and currency of their knowledge base.`,
    description2: `The realization of growth is intricately tied to the activities we engage in. Growth-centric activities depend entirely on the depth of our knowledge base. Contemplating Earth alone reveals myriad growth opportunities. Expanding our perspective to the universe uncovers countless opportunities awaiting exploration. These opportunities surround us, beckoning to be seized, essentially forming a utopia or intelligent society awaiting realization. The speed of growth hinges on the quality of the knowledge base; hence, advancement may be impeded if the knowledge quality is subpar. In today's era, with the potency of technology at our disposal, the key lies in orchestrating its components effectively.`,
    description3: `Through my extensive professional journey, a crucial lesson has been ingrained: "Nothing is impossible in this world." Despite grappling with limitations, technology serves as the conduit to surmount them. The essence of our humanity lies not only in recognizing our constraints but in earnestly seeking solutions to transcend them—a testament to the remarkable capabilities of the human brain. Indeed, in the realm of human achievement, nothing is deemed insurmountable.`,
    description4: `With a well-planned technical approach, wonders can be achieved. This is the ethos of Cortex Smart Design, making villages, cities, countries, and the world intelligent for humans.`,
    buttonLabel: 'Register Nowww',
    imgStart: 'start',
    img: "images/Techp.png",
    img2: "images/Tech2.png",
    img3: "images/Tech3.png",
    alt: '',
    start: '',
    margin: -145,
    buttonstateblog: false,
    padding: '160px 0',
    footer:"By Gourav Choudhury",
    infostate: true,
    blogpic: true,
    redirect: "https://zangie.mounts.in/"
};

export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline:
        'Marie Claire',
    descriptions: `Marie Claire is a leading international monthly magazine founded by Jean Prouvost, first published in France in 1937. Since then, various editions are published in many countries and languages. Today, Marie Claire stands as a compelling media brand globally.`,
    description1:`The magazine typically talks about the latest news and trends in Fashion, Beauty, Celebrity, Home, and Lifestyle. Marie Claire publishes local editions in over 28 countries, in 5 continents. More than a magazine, Marie Claire reaches globally to 17 million readers each month, and has an engaged and growing social media fan-base of more than 26 million followers.`,
    buttonLabel: 'View',
    imgStart: 'start',
    img: 'images/nail1.jpg',
    alt: 'Vault',
    start: 'true',
    buttonstateblog: true,
    infostateblog: true,
    id: "Marie",
    redirect: "https://www.marieclairesalon.in/"
};


export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Zangie ',
  headline: 'Zangie smart cards',
  description:'The age-old wisdom that "you never get a second chance to make a first impression" resonates profoundly, and Zangie smart Cards embody this understanding. They provide professionals with a streamlined, contemporary, and digital substitute for the conventional paper business card. Your initial impact is no longer merely handed over; it arrives in a format that distinctly stands out in the digital era. Join us as we uncover the revolutionary potential of Zangie smart Cards, examining how they are reshaping the landscape of professional connections and networking.',
  buttonLabel: 'Register Now',
  imgStart: 'start',
  img: "images/cardssample.png",
  alt: '',
  start: '',
  margin:-145,
  buttonstateblog:true,
  padding:'160px 0',
  id:"zangie",
  redirect:"https://zangie.mounts.in/"
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'C-Talk Conferences App',
  description:
    "In the era of digital connectivity, conferences have evolved beyond physical venues into dynamic, virtual spaces where ideas converge, and collaborations flourish. To meet the demands of this rapidly changing landscape, we are thrilled to introduce C-Talk – your ultimate conference app designed to redefine the way we connect, communicate, and collaborate in the professional sphere. As the pulse of modern conferences, C-Talk is more than just an app; it's a gateway to seamless, immersive conferencing experiences. Join us on this journey as we unravel the myriad features and advantages that make C-Talk the go-to platform for organizers and attendees alike.",
  buttonLabel: 'Download Now',
  imgStart: 'start',
  img: 'images/ctalk.jpg',
  alt: 'Vault',
  buttonstateblog:true,
  start: '',
  id:"Ctalk",
  redirect:"https://play.google.com/store/apps/details?id=com.mounts.ctalk"
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline:
    'Matrimonial Portals',
  description:`In a world marked by technological advancements, the quest for love and companionship has found a new frontier - online matrimonial portals. As creators of a pioneering platform, we're excited to share the journey of our matrimonial portal and explore how it is transforming the landscape of matchmaking. At the heart of our matrimonial portal is a commitment to providing an exceptional user experience. We've designed an intuitive interface that allows users to create detailed profiles, ensuring that the essence of their personality shines through.
           The platform is user-friendly, making the journey of finding a life partner an enjoyable one`,   
   buttonLabel: 'View',
  imgStart: 'start',
  img: 'images/matrim.jpg',
  alt: 'Vault',
  start: 'true',
  buttonstateblog: true,
  id:"Matrimonial",
  redirect:"https://n4nairmatrimony.com/"
};

export const maindata = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: ' ',
  headline: 'Hotel Management Portal',
  description:
        "Today, we are thrilled to introduce our tailored solution – a custom hotel management portal designed exclusively to elevate your establishment's operations and redefine guest experiences. As the digital heartbeat of modern hotel management, our portal is not just a platform; it's a comprehensive solution meticulously crafted to meet and exceed the unique needs of your property. So, let's embark on this digital expedition together, exploring the remarkable possibilities that our hotel management portal brings to the table. Welcome to a world where innovation meets hospitality.",
  buttonLabel: 'Register Now',
  imgStart: 'start',
  img: 'images/hotel-booking-portal.png',
  buttonstateblog:true,
  alt: 'Vault',
  id:"Hotel",
  redirect:"https://hotelroom.azurewebsites.net/login"
};
export const maindata2 = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: '',
  headline: 'Vendor Management Systems',
  description:
        "In the fast-evolving realm of modern business, traditional manual methods for vendor management face challenges in meeting the dynamic demands of the environment. Enter Vendor Management Systems (VMS), a comprehensive solution crafted to tackle these issues by embedding efficiency, transparency, and strategic importance throughout the entire vendor management process. As businesses expand their networks and forge connections with multiple suppliers, the need for a systematic and streamlined approach becomes increasingly crucial. It is precisely in response to this demand that Vendor Management Systems come into play, reshaping how organizations navigate and oversee their expansive network of vendors.",
  buttonLabel: 'Explore More',
  imgStart: 'start',
  img: 'images/vendor.jpg',
  alt: 'Vault',
   buttonstateblog:true,
  start: '',
  id:"Vendor",
  redirect:"https://vendormanage.azurewebsites.net/login"
};
export const maindata3 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: 'Billing Software',
  description:
        "The era of paper invoices and manual bookkeeping is behind us. Manual billing processes, once time-consuming and error- prone, have given way to a transformative wave with the introduction of business billing software. These digital solutions bring automation to the billing process, providing a plethora of features that streamline and elevate financial operations. Businesses now experience a revolution in how they manage invoicing and payments, thanks to the efficiency and capabilities offered by modern business billing software",
  buttonLabel: 'Explore Now',
  imgStart: 'start',
  img: 'images/bill.svg',
  buttonstateblog:true,
  alt: 'Vault',
  id:'Billing',
  redirect:"https://billing.mounts.in/register"

};
