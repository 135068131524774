export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Mount Software Service',
  headline: 'Create Your Own Cloud Platform Within Seconds',
  description:'Cortex Solution Design is a simple application platform engineered using the latest technology on cloud platforms to help millions of small and big businesses towards having smart systems that will not only provide end to end automation and capture quality data towards getting smart output but also help in identifying best practices using predictive technology',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: "images/hero-img.png",
  alt: '',
  start: '',
  margin:-145,
  padding:'160px 0',
  buttonstate:true
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Smart Approach',
  headline: 'Extremely quick',
  description:
    "MOUNTS|CORTEX App designed to achieve quality Predictive Capability. After years of research and experience in implementing technical solutions to many complex practical problems, MOUNTS|CORTEX App was developed towards providing better solutions to different types of business domains. Like all great challenges, we can’t do it by ourselves: we work closely with our customers, partners, and other friends to push innovation forward, turning MOUNTS|CORTEX App into an online platform for all types of businesses",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/svg-2.svg',
  alt: 'Vault',
  start: ''
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline:
    'About Us',
  /*descriptions:'We are supported by IITians in all sectors of specialized members, especially the technical field. We believe in business success with the support of smart technology and the massive changes it can make in peoples lives all across the world. We want to place a system that helps different business or organization owners take actual benefit of the latest technology.',   
  description1: 'Smart technology will play a big role in building predictive capability for every section of our society. Together our mission is to place smart and decision making systems that will help evolve our society , then we have our job done.',
  description2:'We strongly believe in building PREDICTIVE capability with smart applications for different types of organizations and business units that helps real growth. Well planned; smart process driven systems supported by INTELLIGENT and powerful applications that are simple to manage can MAKE massive changes in peoples lives all across the world. Compilation, stress, long working hours, under performance or failure are due to the gap in the system.',   
  description3: 'With predictive capability we are better prepared. Having information or alert at the right time is a big advantage rather than putting effort after things have gone out of hand. Individuals or organizations should work towards having a smart approach and system that helps in making quality decisions at the right time.  ',
*/
  descriptions: `Guided by a skilled team with 50+ years of experience, we lead in driving business success through innovative technology. Our mission is to deploy cutting-edge systems, empowering businesses to harness advanced technology's full potential.Smart technology plays a pivotal role in foreseeing societal trends, and together, we focus on constructing intelligent decision- making systems for societal advancement.`,   
    description1: 'Our primary focus revolves around nurturing predictive capabilities through intelligent applications tailored for diverse organizations, fostering authentic and sustainable growth. The transformative impact of well-designed, process-driven systems with user-friendly applications extends to improving lives on a global scale. Recognizing that challenges such as stress and underperformance often stem from systemic gaps, we strive to bridge these divides.',
    
  buttonLabel: 'View Case Study',
  imgStart: 'start',
  img: 'images/profile.png',
  video:'images/mountscortex.mp4',
  alt: 'Vault',
  start: 'true',
  infostate:true
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Secure Storage',
  headline: 'Data on cloud secure server',
  description:
    'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-3.svg',
  alt: 'Vault',
  start: 'true'
};

export const maindata = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Easy To Implementation ',
  headline: 'Next Generation technology',
  description:
    "Protect data from common vulnerabilities and exploits with a built-in cloud firewall. A fully managed and stored structured data over the cloud.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/svg-1.svg',
  alt: 'Vault'
};
export const maindata2 = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Instant Setup',
  headline: 'Unique Design',
  description:
    " Cortex App is a simple application platform engineered using the latest technology on cloud platforms to help millions of small and big businesses towards having smart systems that will not only provide end to end automation or capture quality data towards getting smart output but also help in identifying best practices using technology towards achieving predictive capabilityLike all great challenges, we can’t do it by ourselves: we work closely with our customers, partners, and other friends to push innovation forward, turning MOUNTS|CORTEX App into an online platform for all types of businesses.Keeping things simple and user friendly is also taken care of while developing the application. So the application can be used by any institution to improve in respective areas.",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/values1.png',
  alt: 'Vault',
  start: ''
};
export const maindata3 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Cloud Database',
  headline: 'Secured Database',
  description:
    "Database model and design is based on the integrated approach. Highly secured database over the cloud. MOUNTS|CORTEX App most of the real world objects are variable and engineered to capture data at source will become a single repository of quality data. Cortex intelligent program will not only provide quality knowledge as information but provide options that help in planning and prediction.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/values3.png',
  alt: 'Vault'
};
export const maindata4 = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: ' Quality Knowledge',
  headline: ' Predictive Capability System',
  description:
    " Knowledge is directly related to data and quality of data determines the quality of knowledge. Today we have many applications, systems or tools that provide data but quality of data is a big question mark. Getting actual status of data at the right time and without human involvement is always a challenge but Cortex provides the solution.",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/values2.png',
  alt: 'Vault',
  start: ''
};
export const maindata5 = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Easy Setup',
  headline: 'Smart technology',
  description:
    "Smart technology will play a big role in building predictive capability for every section of our society. Together our mission is to place smart and decision making systems that will not only evolve our society, then we have our job done.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/values4.png',
  alt: 'Vault'
};

