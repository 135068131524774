import React from 'react';
import { homeObjBlog, homeObjOne, homeObjTwo, homeObjThree, homeObjFour ,
  maindata,
  maindata2,
  maindata3,
  maindata4,
  maindata5,
 } from './Data';
import { InfoSection, Pricing } from '../../components';

function Blog() {
  return (
    <>
      <InfoSection {...homeObjBlog} />
      <InfoSection {...homeObjFour} />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...maindata} />
      <InfoSection {...maindata2} />
      <InfoSection {...maindata3} />
    </>
  );
}

export default Blog;
