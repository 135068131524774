export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Exclusive Access',
  headline: 'Unlimited Transactions with zero fees',
  description:
    'Get access to our exclusive diamond card that allows you to  send unlimited transactions without getting charged any fees',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/svg-1.svg',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: '100% Secure',
  headline: 'Stay protected 24/7 anywhere anytime',
  description:
    'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/svg-1.svg',
  alt: 'Vault'
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline:
    'About Us',
  descriptions:'We are supported by Smart team in all sectors of specialized members, especially the technical field. We believe in business success with the support of smart technology and the massive changes it can make in peoples lives all across the world. We want to place a system that helps different business or organization owners take actual benefit of the latest technology.',   
  description1: 'Smart technology will play a big role in building predictive capability for every section of our society. Together our mission is to place smart and decision making systems that will not only evolve our society, then we have our job done.',
  description2:'We strongly believe in building PREDICTIVE capability with smart applications for different types organizations and business units that helps real growth. Well planned; smart process driven systems supported by INTELLIGENT and powerful applications that are simple to manage can MAKE massive changes in peoples lives all across the world. Compilation, stress, long working hours, under performance or failure are due to the gap in the system.',   
  description3:'With predictive capability we are better prepared. Having information or alert at the right time is a big advantage rather than putting effort after things have gone out of hand. Individuals or organizations should work towards having a smart approach and system that helps in making quality decisions at the right time.  ',   
  buttonLabel: 'View Case Study',
  imgStart: 'start',
  img: 'images/profile.png',
  alt: 'Vault',
  start: 'true',
  infostate:true
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Data Analytics',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-1.svg',
  alt: 'Vault'
};
