import React from 'react';
import { Button } from '../../globalStyles';
import { GiCloudRing, GiWrappedSweet } from 'react-icons/gi';
import { GiCutDiamond, GiIdCard } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import { IoMdPeople, IoIosCut, IoIosMicrophone, IoIosPaper, IoIosBusiness, IoIosCart } from "react-icons/io";
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardCost,
  PricingCardLength,
  PricingCardFeatures,
  PricingCardFeature
} from './Pricing.elements';
function Pricing() {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Mounts Product</PricingHeading>
          <PricingContainer>
            <PricingCard >
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiIdCard />
                </PricingCardIcon>
                <PricingCardPlan>Zangie Card</PricingCardPlan>
                <PricingCardCost>(Digital Cards)</PricingCardCost>
                <PricingCardLength>Turn paper cards <br /> into digital contacts </PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Cloud Contacts</PricingCardFeature>
                  <PricingCardFeature>Message Customers</PricingCardFeature>
                  <PricingCardFeature>Share Cards Across Platform</PricingCardFeature>
                </PricingCardFeatures>
                           
                     <Button onClick={() => window.location.href="/Blog/#zangie"} primary>Detail</Button>
              </PricingCardInfo>
            </PricingCard>
                      
                      
            <PricingCard >
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiWrappedSweet />
                </PricingCardIcon>
                <PricingCardPlan>Sweet Crush</PricingCardPlan>
                <PricingCardCost>(Game App)</PricingCardCost>
                <PricingCardLength>Sweet Crush is a fun and addictive match-3 puzzle game where you must crush delicious fruits to score points and progress to the next level.</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>Sweet Crush is the sweetest puzzle game.</PricingCardFeature> 
                </PricingCardFeatures>
                              <Button onClick={() => window.open('https://play.google.com/store/apps/details?id=com.mounts.sweetburst')} primary>Download Now</Button>
              </PricingCardInfo>
            </PricingCard>
            <PricingCard >
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCutDiamond />
                </PricingCardIcon>
                <PricingCardPlan>Puzzle Block Color</PricingCardPlan>
                <PricingCardCost>(Game App)</PricingCardCost>
                <PricingCardLength>Get ready to dive into a world of colorful challenges with Puzzle Block Color, the ultimate brain teaser game that will test your wits, strategy, and reflexes.</PricingCardLength>
                <PricingCardFeatures>
                  <PricingCardFeature>A fun and game that is perfect for all ages.</PricingCardFeature>
                  
                </PricingCardFeatures>
                <Button onClick={()=> window.open('https://play.google.com/store/apps/details?id=com.mounts.game')} primary>Download Now</Button>
              </PricingCardInfo>
            </PricingCard>
          </PricingContainer>
              </PricingWrapper>

              {/*<PricingWrapper style={{ marginTop:20 }}>
                  
                  <PricingContainer>
                      <PricingCard >
                          <PricingCardInfo>
                              <PricingCardIcon>
                                  <IoMdPeople />
                                 
                              </PricingCardIcon>
                              <PricingCardPlan>Matrimonial Portal</PricingCardPlan>
                              <PricingCardCost>(Nair Community)</PricingCardCost>
                              <PricingCardLength>Product developed with collaboration to Nair Service Society Delhi </PricingCardLength>
                              <PricingCardFeatures>
                                  <PricingCardFeature>Wedding Portal</PricingCardFeature>
                                  <PricingCardFeature>Mobile App Supported</PricingCardFeature>
                                  <PricingCardFeature>Find your life patner</PricingCardFeature>
                              </PricingCardFeatures>
                             */}{/* <Button onClick={() => window.open('https://n4nairmatrimony.com/')} primary>View Product</Button>*/}{/*
                              <Button onClick={() => window.location.href = "/Blog/#Matrimonial"} primary>Detail</Button>
                          </PricingCardInfo>
                      </PricingCard>
                      <PricingCard >
                          <PricingCardInfo>
                              <PricingCardIcon>
                                  <IoIosCut />
                              </PricingCardIcon>
                              <PricingCardPlan>Marie Claire Salon</PricingCardPlan>
                              <PricingCardCost>(Web Portal)</PricingCardCost>
                              <PricingCardLength>Website that serves as a single point of access to information, services, resources and location. </PricingCardLength>
                              <PricingCardFeatures>
                                  <PricingCardFeature>User friendly and Interactive UI </PricingCardFeature>
                                  <PricingCardFeature>Customized web Portal in 24 Hrs</PricingCardFeature>
                                  
                              </PricingCardFeatures>
                              <Button onClick={() => window.location.href = "/Blog/#Marie"} primary>Detail</Button>
                          </PricingCardInfo>
                      </PricingCard>
                      <PricingCard >
                          <PricingCardInfo>
                              <PricingCardIcon>
                                  <IoIosMicrophone />
                              </PricingCardIcon>
                              <PricingCardPlan>C-TALK</PricingCardPlan>
                              <PricingCardCost>(Conference App)</PricingCardCost>
                              <PricingCardLength>This is a Peer to Peer Voice Conference App In which Admin Can Create User and Broadcast their Voice Message User will be able to Reply to Admin Only</PricingCardLength>
                              <PricingCardFeatures>
                                  
                                  <PricingCardFeature>Mobile and Web both supported</PricingCardFeature>

                              </PricingCardFeatures>
                              
                              <Button onClick={() => window.location.href ="/Blog/#Ctalk"} primary>Detail</Button>
                          </PricingCardInfo>
                      </PricingCard>
                  </PricingContainer>
              </PricingWrapper>*/}

              <PricingWrapper style={{ marginTop: 20 }}>
                  <PricingHeading> Software (Business Tools)</PricingHeading>
                  <PricingContainer>
                      <PricingCard >
                          <center>
                          <PricingCardInfo>
                              <PricingCardIcon>
                                      <IoIosBusiness />
                              </PricingCardIcon>
                                  <PricingCardPlan >Hotel Management System(HMS)</PricingCardPlan>
                              <PricingCardCost>(Auto Scheduler)</PricingCardCost>
                                  <PricingCardLength>Front-desk operations ,Check in, Check out, Reservations, Inventory, Housekeeping, Rate and Occupancy management, and Payment processing. </PricingCardLength>
                              <PricingCardFeatures>
                                  <PricingCardFeature>Customized Reports(Support)</PricingCardFeature>
                                      <PricingCardFeature>Billing and Invoicing</PricingCardFeature>
                                      <PricingCardFeature>Expenses Tracker</PricingCardFeature>

                                      <Button onClick={() => window.location.href = "/Blog/#Hotel"} primary>Detail</Button>
                              </PricingCardFeatures>
                              
                              </PricingCardInfo>
                          </center>
                      </PricingCard>
                      <PricingCard >
                          <center>
                              <PricingCardInfo>
                                  <PricingCardIcon>
                                      <IoIosPaper />
                                  </PricingCardIcon>
                                  <PricingCardPlan >Billing software</PricingCardPlan>
                                  <PricingCardCost>( Automation)</PricingCardCost>
                                  <PricingCardLength>Designed to automate and streamline the invoicing and billing process for businesses.</PricingCardLength>
                                  <PricingCardFeatures>
                                      <PricingCardFeature>Client and Product/Service Database</PricingCardFeature>
                                      <PricingCardFeature>Reporting and Analytics</PricingCardFeature>
                                      <PricingCardFeature>Late Payment Reminders</PricingCardFeature>
                                      <PricingCardFeature>Client Portal</PricingCardFeature>

                                      <Button onClick={() => window.location.href = "/Blog/#Billing"} primary>Detail</Button>
                                  </PricingCardFeatures>

                              </PricingCardInfo>
                          </center>
                      </PricingCard>
                      <PricingCard >
                          <center>
                              <PricingCardInfo>
                                  <PricingCardIcon>
                                      <IoIosCart />
                                  </PricingCardIcon>
                                  <PricingCardPlan >Vendor Management System (VMS)</PricingCardPlan>
                                  
                                  <PricingCardLength>Helps  businesses to efficiently manage their relationships with external suppliers, vendors, and service providers.</PricingCardLength>
                                  <PricingCardFeatures>
                                      <PricingCardFeature>Vendor Onboarding</PricingCardFeature>
                                      <PricingCardFeature>Contract/Order Management</PricingCardFeature>
                                      <PricingCardFeature>Production Management</PricingCardFeature>
                                      <PricingCardFeature>Inventory Management Systerm</PricingCardFeature>

                                      <Button onClick={() => window.location.href = "/Blog/#Vendor"} primary>Detail</Button>
                                  </PricingCardFeatures>

                              </PricingCardInfo>
                          </center>
                      </PricingCard>
                        </PricingContainer>
              </PricingWrapper>
       
      </PricingSection>
    </IconContext.Provider>
  );
}
export default Pricing;
