import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from '../../globalStyles';
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  TopLine2,
  Heading,
  Subtitle,
  Subtitle1,
  ImgWrapper,
  Img
} from './InfoSection.elements';

function InfoSection({
  primary,
  lightBg,
  topLine,
  topLine2,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  descriptions,
  description1,
  description2,
  description3,
  buttonLabel,
  img,
  img2,
  img3,
  alt,
  imgStart,
  start,
  margin,
  buttonstate,
  infostate,
  video,
  padding,
  id,
  buttonstateblog,
  redirect,
  blogpic,
  footer,
  infostateblog
}) {
  return (
      <>
          <InfoSec id={id} style={{ padding: padding }} lightBg={lightBg}>
              <Container style={{ marginTop: margin }}>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                <TopLine2 lightTopLine={lightText}>{topLine2}</TopLine2>
                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}
                </Subtitle>
                {infostate === true ? <Subtitle1 lightTextDesc={lightTextDesc}>{descriptions}<br / >  <br / > {description1} 
                <br / > <br / >  {description2}
                <br / > <br / >  {description3}
                </Subtitle1>:null}
                {infostateblog === true ? <Subtitle lightTextDesc={lightTextDesc}>{descriptions}<br / >  <br / > {description1} 
                <br / > <br / >  {description2}
                </Subtitle>:null}
                
              {buttonstate === true? 
              <Link onClick={()=>window.open('https://a.mounts.in/Registration')}>
                  <Button  big fontBig primary={primary}>
                    {buttonLabel}
                  </Button>
               </Link>: null}
             {buttonstateblog === true? 
                  <Button onClick={()=> window.open(redirect)} big fontBig primary={primary}>
                    {buttonLabel}
                  </Button>:null}
               {blogpic === true ? <Heading lightText={lightText}>{footer}</Heading>:null}
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
             {blogpic === true ?<>  <ImgWrapper start={start}>
                    {video ? <iframe style={{ minWidth: "100%", height: 340 }} src="https://www.youtube.com/embed/aQEliLKfuno" title="mountscortex" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                       : <Img src={img} alt={alt} />}

              </ImgWrapper><br/><br/><ImgWrapper start={start}>
                    {video ?
                      <iframe style={{ minWidth: "100%", height: 340 }} src="https://www.youtube.com/embed/aQEliLKfuno" title="mountscortex" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                       : <Img src={img2} alt={alt} />}

              </ImgWrapper><br/><br/><ImgWrapper start={start}>
                    {video ?
                      <iframe style={{ minWidth: "100%", height: 340 }} src="https://www.youtube.com/embed/aQEliLKfuno" title="mountscortex" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                       : <Img src={img3} alt={alt} />}

              </ImgWrapper></> : <ImgWrapper start={start}>
                              {video ?
                                  <iframe style={{ minWidth: "100%", height: 340 }} src="https://www.youtube.com/embed/aQEliLKfuno" title="mountscortex" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                  : <Img src={img} alt={alt} />}

                          </ImgWrapper>}
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
}

export default InfoSection;
